import React from 'react';
import { InputLabel, useTheme } from '@mui/material';

type Props = {
  errorMessage: string;
};

export const InputErrorLabel = ({ errorMessage }: Props) => {
  const theme = useTheme();

  return (
    <InputLabel sx={{ color: theme.palette.error.main }}>
      {errorMessage}
    </InputLabel>
  );
};
