import React from 'react';
import { StrapiMenu } from '../../api/menu';
import { StrapiFooter } from '../../api/footer';
import { Menu } from '@/components/menu';
import { Footer } from '@/components/footer';
import { StrapiContactUs } from '../../api/contact-us';
import { Section2 } from '@/components/sections/contact-us/Section2';
import { Section1 } from '@/components/sections/contact-us/Section1';
import { SectionWithMap } from '@/components/section-with-map';
import { Head as HeadComponent } from '@/components/head';

export default function ContactUs({
  pageContext: { strapiMenu, strapiFooter, strapiContactUs },
}: {
  pageContext: {
    strapiMenu: StrapiMenu;
    strapiFooter: StrapiFooter;
    strapiContactUs: StrapiContactUs;
  };
}) {
  const headMeta = {
    title: 'Treeline Interactive',
    meta: {
      title: 'Contact | Treeline Interactive',
      description: 'Contact Us here at Treeline Interactive. Thanks for stopping by the site. We would love to hear from you! Please let us know how Treeline Interactive can help you with your project!',
      keywords: 'san diego, california,  interactive agency, online marketing, iphone development, treeline interactive, mission beach, 3725 mission blvd, 92109',
    },
  };

  return (
    <>
      <HeadComponent
        defaultTitle={headMeta.title}
        defaultMeta={headMeta.meta}
        metadata={strapiContactUs.metadata}
        image={strapiContactUs.metadata?.OGImage?.data?.attributes.url
          || strapiMenu.logo.data.attributes.url} />
      <Menu strapiMenu={strapiMenu} />
      <Section1 section={strapiContactUs.section1} />
      <Section2 />
      <SectionWithMap offices={strapiContactUs.section3.offices.data} />
      <Footer
        strapiFooter={strapiFooter}
        backgroundColor="greyscale.cultured"
      />
    </>
  );
}
