import { hubspotFormClient } from '@/api/hubspot-client';

const createSubmission = async ({
  formId,
  body,
}: {
  formId: string;
  body: any;
}) => {
  const response = await hubspotFormClient.post<any>(
    `/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${formId}`,
    { fields: body },
  );
  return response.data;
};

export default createSubmission;
