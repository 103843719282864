import React from 'react';
import { navigate } from 'gatsby';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ObjectTyped } from 'object-typed';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import createSubmission from '@/api/contact-form';
import { InputErrorLabel } from '@/components/input-error-label';
import { TrackedButton } from '@/components/tracked-button';
import { budgets, findUsSources, industries } from './options';

const phoneRegExp = /^(1\s?)?(\d{3}|\(\d{3}\))[\s-]?\d{3}[\s-]?\d{4}$/g;

const ContactFormSchema = Yup.object().shape({
  '0-2/name': Yup.string().required('Required'),
  '0-2/your_budget': Yup.string().required('Required'),
  '0-2/industry': Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  firstname: Yup.string().required('Required'),
  how_did_you_find_us_: Yup.string().required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export type Props = {
  formId: string;
};

export const HubspotForm = ({ formId }: Props) => {
  const formik = useFormik({
    initialValues: {
      '0-2/website': '',
      '0-2/name': '',
      '0-2/your_budget': '',
      '0-2/industry': '',
      email: '',
      phone: '',
      lastname: '',
      firstname: '',
      project_scope: '',
      existing_resources_1: '',
      existing_resources_2: '',
      existing_resources_3: '',
      how_did_you_find_us_: '',
    },
    validationSchema: ContactFormSchema,
    onSubmit: (values) => {
      const body = ObjectTyped.keys(values).map((key) => {
        const newKey = key.includes('existing_resources')
          ? 'existing_resources'
          : key;

        return {
          name: newKey,
          value: values[key],
        };
      });

      createSubmission({ formId, body })
        .then(() => {
          navigate('/?submission');
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          mb: { xs: 2, md: 4 },
        }}
      >
        <Grid container maxWidth={800}>
          <Grid item xs={12} sm={6} sx={{ pr: { sm: 1 }, pb: 2 }}>
            <InputLabel> First name </InputLabel>
            <TextField
              onChange={formik.handleChange}
              value={formik.values.firstname}
              fullWidth
              sx={{ fontSize: '12px' }}
              id={'firstname'}
              name={'firstname'}
              placeholder={'First name'}
            />

            {formik.errors.firstname && formik.touched.firstname && (
              <InputErrorLabel errorMessage={formik.errors.firstname} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pl: { sm: 1 }, pb: 2 }}>
            <InputLabel> Last name </InputLabel>
            <TextField
              onChange={formik.handleChange}
              value={formik.values.lastname}
              fullWidth
              id={'lastname'}
              name={'lastname'}
              placeholder={'Last name'}
            />
            {formik.errors.lastname && formik.touched.lastname && (
              <InputErrorLabel errorMessage={formik.errors.lastname} />
            )}
          </Grid>
          <Grid item sm={6} xs={12} sx={{ pr: { sm: 1 }, pb: 2 }}>
            <InputLabel> Email Address </InputLabel>
            <TextField
              onChange={formik.handleChange}
              value={formik.values.email}
              fullWidth
              id={'email'}
              name={'email'}
              placeholder={'your@email.com'}
            />
            {formik.errors.email && formik.touched.email && (
              <InputErrorLabel errorMessage={formik.errors.email} />
            )}
          </Grid>
          <Grid item sm={6} xs={12} sx={{ pl: { sm: 1 }, pb: 2 }}>
            <InputLabel> Phone Number </InputLabel>
            <TextField
              onChange={formik.handleChange}
              value={formik.values.phone}
              fullWidth
              id={'phone'}
              name={'phone'}
              placeholder={'1 (123) 4567890'}
            />
            {formik.errors.phone && formik.touched.phone && (
              <InputErrorLabel errorMessage={formik.errors.phone} />
            )}
          </Grid>
          <Grid item sm={6} xs={12} sx={{ pr: { sm: 1 }, pb: 2 }}>
            <InputLabel> Company Name </InputLabel>
            <TextField
              onChange={formik.handleChange}
              value={formik.values['0-2/name']}
              fullWidth
              id={'0-2/name'}
              name={'0-2/name'}
              placeholder={'Company name'}
            />
            {formik.errors['0-2/name'] && formik.touched['0-2/name'] && (
              <InputErrorLabel errorMessage={formik.errors['0-2/name']} />
            )}
          </Grid>
          <Grid item sm={6} xs={12} sx={{ pl: { sm: 1 }, pb: 2 }}>
            <InputLabel> Company Type </InputLabel>
            <Select
              onChange={formik.handleChange}
              fullWidth
              id="0-2/industry"
              name={'0-2/industry'}
              value={formik.values['0-2/industry']}
              sx={{
                '& .MuiSelect-select .notranslate::after': {
                  content: '"- Select -"',
                  opacity: 0.42,
                },
              }}
            >
              {industries.map(({ value, text }) => (
                <MenuItem
                  key={`${value}${text}`}
                  sx={{ fontSize: '12px' }}
                  value={value}
                >
                  {text}
                </MenuItem>
              ))}
            </Select>
            {formik.errors['0-2/industry'] &&
              formik.touched['0-2/industry'] && (
              <InputErrorLabel errorMessage={formik.errors['0-2/industry']} />
            )}
          </Grid>
          <Grid item sm={6} xs={12} sx={{ pr: { sm: 1 }, pb: 2 }}>
            <InputLabel> Company URL </InputLabel>
            <TextField
              onChange={formik.handleChange}
              value={formik.values['0-2/website']}
              fullWidth
              id={'0-2/website'}
              name={'0-2/website'}
              placeholder={'Company URL'}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pl: { sm: 1 }, pb: 2 }}>
            <InputLabel> Your Budget </InputLabel>
            <Select
              onChange={formik.handleChange}
              fullWidth
              id="0-2/your_budget"
              name={'0-2/your_budget'}
              placeholder={'- Select -'}
              value={formik.values['0-2/your_budget']}
              sx={{
                '& .MuiSelect-select .notranslate::after': {
                  content: '"- Select -"',
                  opacity: 0.42,
                },
              }}
            >
              {budgets.map(({ value, text }) => (
                <MenuItem key={`${value}${text}`} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
            {formik.errors['0-2/your_budget'] &&
              formik.touched['0-2/your_budget'] && (
              <InputErrorLabel
                errorMessage={formik.errors['0-2/your_budget']}
              />
            )}
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <InputLabel> Project scope </InputLabel>
            <TextField
              onChange={formik.handleChange}
              multiline
              rows={5}
              fullWidth
              value={formik.values.project_scope}
              placeholder={'Tell Us About Your Project'}
              id={'project_scope'}
              name={'project_scope'}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <InputLabel> How Did You Find Us ? </InputLabel>
            <Select
              onChange={formik.handleChange}
              fullWidth
              id="how_did_you_find_us_"
              name={'how_did_you_find_us_'}
              value={formik.values.how_did_you_find_us_}
              sx={{
                '& .MuiSelect-select .notranslate::after': {
                  content: '"- Select -"',
                  opacity: 0.42,
                },
              }}
            >
              {findUsSources.map(({ value, text }) => (
                <MenuItem value={value} key={text}>
                  {text}
                </MenuItem>
              ))}
            </Select>
            {formik.errors['how_did_you_find_us_'] &&
              formik.touched['how_did_you_find_us_'] && (
              <InputErrorLabel
                errorMessage={formik.errors['how_did_you_find_us_']}
              />
            )}
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <InputLabel> Existing Resources </InputLabel>
            <FormControlLabel
              label={<Typography variant={'body3'}>Documentation</Typography>}
              control={
                <Checkbox
                  onChange={() => {
                    formik.setFieldValue(
                      'existing_resources_1',
                      'Documentation',
                    );
                  }}
                  id="existing_resources_1"
                  name={'existing_resources_1'}
                  value={formik.values.existing_resources_1}
                />
              }
              sx={{
                display: { xs: 'block', sm: 'inline-flex' },
              }}
            />
            <FormControlLabel
              label={<Typography variant={'body3'}>Designs</Typography>}
              control={
                <Checkbox
                  id="existing_resources_2"
                  name={'existing_resources_2'}
                  value={formik.values.existing_resources_2}
                  onChange={() => {
                    formik.setFieldValue('existing_resources_2', 'Designs');
                  }}
                />
              }
              sx={{
                display: { xs: 'block', sm: 'inline-flex' },
              }}
            />
            <FormControlLabel
              label={<Typography variant={'body3'}>Existing Code</Typography>}
              control={
                <Checkbox
                  onChange={() => {
                    formik.setFieldValue(
                      'existing_resources_3',
                      'Existing Code',
                    );
                  }}
                  id="existing_resources_3"
                  name={'existing_resources_3'}
                  value={formik.values.existing_resources_3}
                />
              }
              sx={{
                display: { xs: 'block', sm: 'inline-flex' },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <TrackedButton
        variant="contained"
        type="submit"
        sx={{
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        Submit
      </TrackedButton>
    </form>
  );
};
