export const industries = [
  { text: 'Accounting', value: 'ACCOUNTING' },
  { text: 'Airlines/Aviation', value: 'AIRLINES_AVIATION' },
  {
    text: 'Alternative Dispute Resolution',
    value: 'ALTERNATIVE_DISPUTE_RESOLUTION',
  },
  { text: 'Alternative Medicine', value: 'ALTERNATIVE_MEDICINE' },
  { text: 'Animation', value: 'ANIMATION' },
  { text: 'Apparel & Fashion', value: 'APPAREL_FASHION' },
  { text: 'Architecture & Planning', value: 'ARCHITECTURE_PLANNING' },
  { text: 'Arts and Crafts', value: 'ARTS_AND_CRAFTS' },
  { text: 'Automotive', value: 'AUTOMOTIVE' },
  { text: 'Aviation & Aerospace', value: 'AVIATION_AEROSPACE' },
  { text: 'Banking', value: 'BANKING' },
  { text: 'Biotechnology', value: 'BIOTECHNOLOGY' },
  { text: 'Broadcast Media', value: 'BROADCAST_MEDIA' },
  { text: 'Building Materials', value: 'BUILDING_MATERIALS' },
  {
    text: 'Business Supplies and Equipment',
    value: 'BUSINESS_SUPPLIES_AND_EQUIPMENT',
  },
  { text: 'Capital Markets', value: 'CAPITAL_MARKETS' },
  { text: 'Chemicals', value: 'CHEMICALS' },
  { text: 'Civic & Social Organization', value: 'CIVIC_SOCIAL_ORGANIZATION' },
  { text: 'Civil Engineering', value: 'CIVIL_ENGINEERING' },
  { text: 'Commercial Real Estate', value: 'COMMERCIAL_REAL_ESTATE' },
  { text: 'Computer & Network Security', value: 'COMPUTER_NETWORK_SECURITY' },
  { text: 'Computer Games', value: 'COMPUTER_GAMES' },
  { text: 'Computer Hardware', value: 'COMPUTER_HARDWARE' },
  { text: 'Computer Networking', value: 'COMPUTER_NETWORKING' },
  { text: 'Computer Software', value: 'COMPUTER_SOFTWARE' },
  { text: 'Internet', value: 'INTERNET' },
  { text: 'Construction', value: 'CONSTRUCTION' },
  { text: 'Consumer Electronics', value: 'CONSUMER_ELECTRONICS' },
  { text: 'Consumer Goods', value: 'CONSUMER_GOODS' },
  { text: 'Consumer Services', value: 'CONSUMER_SERVICES' },
  { text: 'Cosmetics', value: 'COSMETICS' },
  { text: 'Dairy', value: 'DAIRY' },
  { text: 'Defense & Space', value: 'DEFENSE_SPACE' },
  { text: 'Design', value: 'DESIGN' },
  { text: 'Education Management', value: 'EDUCATION_MANAGEMENT' },
  { text: 'E-Learning', value: 'E_LEARNING' },
  {
    text: 'Electrical/Electronic Manufacturing',
    value: 'ELECTRICAL_ELECTRONIC_MANUFACTURING',
  },
  { text: 'Entertainment', value: 'ENTERTAINMENT' },
  { text: 'Environmental Services', value: 'ENVIRONMENTAL_SERVICES' },
  { text: 'Events Services', value: 'EVENTS_SERVICES' },
  { text: 'Executive Office', value: 'EXECUTIVE_OFFICE' },
  { text: 'Facilities Services', value: 'FACILITIES_SERVICES' },
  { text: 'Farming', value: 'FARMING' },
  { text: 'Financial Services', value: 'FINANCIAL_SERVICES' },
  { text: 'Fine Art', value: 'FINE_ART' },
  { text: 'Fishery', value: 'FISHERY' },
  { text: 'Food & Beverages', value: 'FOOD_BEVERAGES' },
  { text: 'Food Production', value: 'FOOD_PRODUCTION' },
  { text: 'Fund-Raising', value: 'FUND_RAISING' },
  { text: 'Furniture', value: 'FURNITURE' },
  { text: 'Gambling & Casinos', value: 'GAMBLING_CASINOS' },
  { text: 'Glass, Ceramics & Concrete', value: 'GLASS_CERAMICS_CONCRETE' },
  { text: 'Government Administration', value: 'GOVERNMENT_ADMINISTRATION' },
  { text: 'Government Relations', value: 'GOVERNMENT_RELATIONS' },
  { text: 'Graphic Design', value: 'GRAPHIC_DESIGN' },
  {
    text: 'Health, Wellness and Fitness',
    value: 'HEALTH_WELLNESS_AND_FITNESS',
  },
  { text: 'Higher Education', value: 'HIGHER_EDUCATION' },
  { text: 'Hospital & Health Care', value: 'HOSPITAL_HEALTH_CARE' },
  { text: 'Hospitality', value: 'HOSPITALITY' },
  { text: 'Human Resources', value: 'HUMAN_RESOURCES' },
  { text: 'Import and Export', value: 'IMPORT_AND_EXPORT' },
  { text: 'Individual & Family Services', value: 'INDIVIDUAL_FAMILY_SERVICES' },
  { text: 'Industrial Automation', value: 'INDUSTRIAL_AUTOMATION' },
  { text: 'Information Services', value: 'INFORMATION_SERVICES' },
  {
    text: 'Information Technology and Services',
    value: 'INFORMATION_TECHNOLOGY_AND_SERVICES',
  },
  { text: 'Insurance', value: 'INSURANCE' },
  { text: 'International Affairs', value: 'INTERNATIONAL_AFFAIRS' },
  {
    text: 'International Trade and Development',
    value: 'INTERNATIONAL_TRADE_AND_DEVELOPMENT',
  },
  { text: 'Investment Banking', value: 'INVESTMENT_BANKING' },
  { text: 'Investment Management', value: 'INVESTMENT_MANAGEMENT' },
  { text: 'Judiciary', value: 'JUDICIARY' },
  { text: 'Law Enforcement', value: 'LAW_ENFORCEMENT' },
  { text: 'Law Practice', value: 'LAW_PRACTICE' },
  { text: 'Legal Services', value: 'LEGAL_SERVICES' },
  { text: 'Legislative Office', value: 'LEGISLATIVE_OFFICE' },
  { text: 'Leisure, Travel & Tourism', value: 'LEISURE_TRAVEL_TOURISM' },
  { text: 'Libraries', value: 'LIBRARIES' },
  { text: 'Logistics and Supply Chain', value: 'LOGISTICS_AND_SUPPLY_CHAIN' },
  { text: 'Luxury Goods & Jewelry', value: 'LUXURY_GOODS_JEWELRY' },
  { text: 'Machinery', value: 'MACHINERY' },
  { text: 'Management Consulting', value: 'MANAGEMENT_CONSULTING' },
  { text: 'Maritime', value: 'MARITIME' },
  { text: 'Market Research', value: 'MARKET_RESEARCH' },
  { text: 'Marketing and Advertising', value: 'MARKETING_AND_ADVERTISING' },
  {
    text: 'Mechanical or Industrial Engineering',
    value: 'MECHANICAL_OR_INDUSTRIAL_ENGINEERING',
  },
  { text: 'Media Production', value: 'MEDIA_PRODUCTION' },
  { text: 'Medical Devices', value: 'MEDICAL_DEVICES' },
  { text: 'Medical Practice', value: 'MEDICAL_PRACTICE' },
  { text: 'Mental Health Care', value: 'MENTAL_HEALTH_CARE' },
  { text: 'Military', value: 'MILITARY' },
  { text: 'Mining & Metals', value: 'MINING_METALS' },
  { text: 'Motion Pictures and Film', value: 'MOTION_PICTURES_AND_FILM' },
  { text: 'Museums and Institutions', value: 'MUSEUMS_AND_INSTITUTIONS' },
  { text: 'Music', value: 'MUSIC' },
  { text: 'Nanotechnology', value: 'NANOTECHNOLOGY' },
  { text: 'Newspapers', value: 'NEWSPAPERS' },
  {
    text: 'Non-Profit Organization Management',
    value: 'NON_PROFIT_ORGANIZATION_MANAGEMENT',
  },
  { text: 'Oil & Energy', value: 'OIL_ENERGY' },
  { text: 'Online Media', value: 'ONLINE_MEDIA' },
  { text: 'Outsourcing/Offshoring', value: 'OUTSOURCING_OFFSHORING' },
  { text: 'Package/Freight Delivery', value: 'PACKAGE_FREIGHT_DELIVERY' },
  { text: 'Packaging and Containers', value: 'PACKAGING_AND_CONTAINERS' },
  { text: 'Paper & Forest Products', value: 'PAPER_FOREST_PRODUCTS' },
  { text: 'Performing Arts', value: 'PERFORMING_ARTS' },
  { text: 'Pharmaceuticals', value: 'PHARMACEUTICALS' },
  { text: 'Philanthropy', value: 'PHILANTHROPY' },
  { text: 'Photography', value: 'PHOTOGRAPHY' },
  { text: 'Plastics', value: 'PLASTICS' },
  { text: 'Political Organization', value: 'POLITICAL_ORGANIZATION' },
  { text: 'Primary/Secondary Education', value: 'PRIMARY_SECONDARY_EDUCATION' },
  { text: 'Printing', value: 'PRINTING' },
  {
    text: 'Professional Training & Coaching',
    value: 'PROFESSIONAL_TRAINING_COACHING',
  },
  { text: 'Program Development', value: 'PROGRAM_DEVELOPMENT' },
  { text: 'Public Policy', value: 'PUBLIC_POLICY' },
  {
    text: 'Public Relations and Communications',
    value: 'PUBLIC_RELATIONS_AND_COMMUNICATIONS',
  },
  { text: 'Public Safety', value: 'PUBLIC_SAFETY' },
  { text: 'Publishing', value: 'PUBLISHING' },
  { text: 'Railroad Manufacture', value: 'RAILROAD_MANUFACTURE' },
  { text: 'Ranching', value: 'RANCHING' },
  { text: 'Real Estate', value: 'REAL_ESTATE' },
  {
    text: 'Recreational Facilities and Services',
    value: 'RECREATIONAL_FACILITIES_AND_SERVICES',
  },
  { text: 'Religious Institutions', value: 'RELIGIOUS_INSTITUTIONS' },
  { text: 'Renewables & Environment', value: 'RENEWABLES_ENVIRONMENT' },
  { text: 'Research', value: 'RESEARCH' },
  { text: 'Restaurants', value: 'RESTAURANTS' },
  { text: 'Retail', value: 'RETAIL' },
  { text: 'Security and Investigations', value: 'SECURITY_AND_INVESTIGATIONS' },
  { text: 'Semiconductors', value: 'SEMICONDUCTORS' },
  { text: 'Shipbuilding', value: 'SHIPBUILDING' },
  { text: 'Sporting Goods', value: 'SPORTING_GOODS' },
  { text: 'Sports', value: 'SPORTS' },
  { text: 'Staffing and Recruiting', value: 'STAFFING_AND_RECRUITING' },
  { text: 'Supermarkets', value: 'SUPERMARKETS' },
  { text: 'Telecommunications', value: 'TELECOMMUNICATIONS' },
  { text: 'Textiles', value: 'TEXTILES' },
  { text: 'Think Tanks', value: 'THINK_TANKS' },
  { text: 'Tobacco', value: 'TOBACCO' },
  {
    text: 'Translation and Localization',
    value: 'TRANSLATION_AND_LOCALIZATION',
  },
  {
    text: 'Transportation/Trucking/Railroad',
    value: 'TRANSPORTATION_TRUCKING_RAILROAD',
  },
  { text: 'Utilities', value: 'UTILITIES' },
  {
    text: 'Venture Capital & Private Equity',
    value: 'VENTURE_CAPITAL_PRIVATE_EQUITY',
  },
  { text: 'Veterinary', value: 'VETERINARY' },
  { text: 'Warehousing', value: 'WAREHOUSING' },
  { text: 'Wholesale', value: 'WHOLESALE' },
  { text: 'Wine and Spirits', value: 'WINE_AND_SPIRITS' },
  { text: 'Wireless', value: 'WIRELESS' },
  { text: 'Writing and Editing', value: 'WRITING_AND_EDITING' },
];

export const budgets = [
  { text: 'Under $50,000', value: 'Under $50,000' },
  { text: '$50,000 - $150,000', value: '$50,000 - $150,000' },
  { text: '$150,000 - $300,000', value: '$150,000 - $300,000' },
  { text: '$300,000 - $500,000', value: '$300,000 - $500,000' },
  { text: '$500,000 +', value: '$500,000 +' },
];

export const findUsSources = [
  { text: 'Google', value: 'Google' },
  { text: 'Word of Mouth', value: 'Word of Mouth' },
  { text: 'Clutch.co', value: 'Clutch.co' },
  { text: 'LinkedIn', value: 'LinkedIn' },
  { text: 'Facebook', value: 'Facebook' },
  { text: 'Manifest', value: 'Manifest' },
];
