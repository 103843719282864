import React from 'react';
import { MainColumn } from '@/components/main-column';
import { HubspotForm } from '@/components/hubspot-form';
import { Typography } from '@mui/material';

export const Section2 = () => (
  <MainColumn
    sx={{
      pt: { xs: 6, md: 8 },
      pb: 7,
    }}
  >
    <Typography
      variant="h2"
      sx={{
        mb: { xs: 3, md: 6 },
      }}
    >
      Let&apos;s Co-Invent the future
    </Typography>
    <HubspotForm formId="5429837f-7d05-42d4-b7c2-7b46d13d975c" />
  </MainColumn>
);
